@import "stylesheets/decidim/variables";
@import "stylesheets/decidim/utils/settings";
@import "stylesheets/decidim/utils/mixins";

.editor-loading{
  &::before{
    @include spinner(25px, $medium-gray, var(--primary), 800ms);

    float: right;
    content: "";
    vertical-align: middle;
  }
}
// some fix for the admin
.form-group {
  .ql-container {
    background-color: white;
  }
}

// some fix for the public part
.editor-container{
  overflow: hidden;
}

.editor.hashtags__container {
  >p.help-text {
    margin-top: -1rem;
    margin-bottom: 1.5rem;
  } 
} 

// For quill editors in custom fields
.form-control[type="richtext"] {
  >p.help-text {
   margin-top: .2rem !important;
  }
  .ql-snow.ql-toolbar {
    button {
      width: 22px;
      margin: 1px 0;
    }
    + .ql-container.ql-snow {
      border-radius: 0;
    }
  }
}

.proposal_custom_fields_editor {
  .form-wrap.form-builder {
    .form-group > .form-control[type="richtext"] {
      padding: 0;
      border: none;
    }
  }
}